import { FC, ReactNode, useEffect, useState } from 'react';

import { useFeaturedFlag, useShoppingMode } from '@/hooks';
import { useAnalytics, useEcomStoreSelector, useUiStoreSelector } from '@/data';
import { ILocalAddress, useData } from '@/components';
import { HeaderSlice, TreezStore } from '@/types';
import { StoreLocatorContext } from './context';

import style from './storelocator.module.scss';
import classNames from 'classnames';
import { getExternalStores } from '@/utils';
interface IStoreLocatorProviderProps {
  children: ReactNode;
}
const StoreLocatorProvider: FC<IStoreLocatorProviderProps> = ({ children }) => {
  const { treezStores } = useData();
  const externalStores = getExternalStores();
  const { measureSurfsideChangeLocation } = useAnalytics();
  const { forceToSelectStore } = useFeaturedFlag();

  const [stores, setStores] = useState<TreezStore[]>([]);
  const {
    is_crm_modal_open,
    terms_accepted,
    treez_store_name,
    delivery_details,
    delivery_address,
    setState,
  } = useEcomStoreSelector([
    'treez_store_name',
    'delivery_details',
    'delivery_address',
    'is_crm_modal_open',
    'terms_accepted',
  ]);
  const { is_store_location_open, setState: setUiState } = useUiStoreSelector([
    'is_store_location_open',
  ]);

  const { singleStore, onlyPickup, onlyDelivery, deliveryAndPickup } =
    useShoppingMode();

  const [isDelivery, setIsDelivery] = useState<boolean>(false);
  const [isPickup, setIsPickup] = useState<boolean>(false);
  const [deliveryAddress, setDeliveryAddress] = useState<
    ILocalAddress | undefined
  >();
  const [storeAddress, setStoreAddress] = useState('');

  const [isDeliveryAddressInvalid, setIsDeliveryAddressInvalid] =
    useState(false);

  const [isPickupInvalid, setIsPickupInvalid] = useState(false);

  const [selectedStoreId, setSelectedStoreId] = useState<string>('');

  const [showOverlay, setShowOverlay] = useState(false);

  useEffect(() => {
    // if the store offers pickup and delivery, select pickup by default, if it offer only
    // delivery, select delivery by default
    const storeShortName = treezStores[0].shortName;

    if (singleStore && !forceToSelectStore) {
      setState(state => {
        if (
          !state.delivery_details.pickup &&
          !state.delivery_details.delivery
        ) {
          return {
            delivery_details: {
              delivery: onlyDelivery,
              pickup: !onlyDelivery,
            },
            treez_store_name: storeShortName,
          };
        }
        return state;
      });

      measureSurfsideChangeLocation(storeShortName);
    }
  }, []);

  // this is a feature flag that forces the user
  // to select a store
  useEffect(() => {
    if (forceToSelectStore) {
      // this is a feature flag
      if (
        !treez_store_name && // not store selected
        !is_crm_modal_open && // cmr modal is not open
        terms_accepted // age gate accepted
      ) {
        setShowOverlay(true);
        setUiState({ is_store_location_open: true });
      }
    }
  }, [
    is_store_location_open,
    terms_accepted,
    is_crm_modal_open,
    forceToSelectStore,
    singleStore,
    treez_store_name,
  ]);

  useEffect(() => {
    // pickup takes precedence over delivery when both flags on delivery_details are active
    // NOTE: if delivery_details.pickup/delivery should NOT prioritize pickup when both are active,
    // remove this block and let delivery-check execute first instead.
    if (onlyPickup || delivery_details.pickup) {
      setIsPickup(true);
      setIsDelivery(false);
      return;
    }

    if (onlyDelivery || delivery_details.delivery) {
      setIsPickup(false);
      setIsDelivery(true);
      return;
    }

    setIsPickup(true);
    setIsDelivery(false);
  }, [
    delivery_details.delivery,
    delivery_details.pickup,
    onlyDelivery,
    onlyPickup,
    is_store_location_open,
  ]);

  useEffect(() => {
    // by default, select the only available store
    if (
      singleStore &&
      !treez_store_name &&
      forceToSelectStore &&
      !onlyDelivery
    ) {
      const storeShortName = treezStores[0].shortName;
      setSelectedStoreId(storeShortName);
      return;
    }

    setSelectedStoreId(treez_store_name);
  }, [treez_store_name, is_store_location_open]);

  useEffect(() => {
    if (delivery_details?.delivery) {
      setDeliveryAddress(delivery_address as ILocalAddress);
    }
  }, [delivery_address]);

  // filter the stores based on the shopping mode and
  // always show the selected store first
  useEffect(() => {
    const fs = treezStores.filter(store =>
      isDelivery ? store.delivery : store.pickup,
    );

    const t = fs.find(store => store.shortName === selectedStoreId);

    if (!t) {
      setStores(fs);
      return;
    }

    setStores([t, ...fs.filter(store => store.shortName !== selectedStoreId)]);
  }, [is_store_location_open, isDelivery, isPickup]);

  const {
    header: { data: header },
  } = useData();

  const activeSlice = (header?.slices || []).find(
    (s: HeaderSlice) => s?.primary?.active,
  );

  return (
    <StoreLocatorContext
      value={{
        deliveryAddress: deliveryAddress as ILocalAddress,
        deliveryAndPickup,
        isDelivery,
        isDeliveryAddressInvalid,
        isPickup,
        isPickupInvalid,
        onlyDelivery,
        onlyPickup,
        selectedStoreId,
        setDeliveryAddress,
        setIsDelivery,
        setIsDeliveryAddressInvalid,
        setIsPickup,
        setIsPickupInvalid,
        setSelectedStoreId,
        setStoreAddress,
        setStores,
        singleStore,
        storeAddress,
        stores,
        externalStores,
        showOverlay,
        setShowOverlay,
        isDeliveryFromEconStore: !!delivery_details?.delivery,
      }}
    >
      <div
        className={classNames(style.store_locator__container, {
          [style.store_locator__container__centered_single_row]:
            activeSlice?.variation === 'withCenteredLogoAndLocatorFix',
        })}
      >
        {children}
      </div>
    </StoreLocatorContext>
  );
};

export { StoreLocatorProvider };
