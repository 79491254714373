/* eslint-disable max-len */

export const TOTAL_CANNABINOIDS = {
  labId: 'TOTAL_CANNABINOIDS',
  name: 'Total Cannabinoids',
  short: 'TAC',
};

export const THC_COMPOUNDS = [
  'THC',
  'THCA',
  'THCV',
  'DELTA-8 THC',
  'DELTA-9 THC',
  'DELTA-8 THCV',
];

export const CBD_COMPOUNDS = [
  'CBD',
  'CBDA',
  'CBDV',
  'CBDVa',
  'CBC',
  'CBCa',
  'CBG',
  'CBGA',
  'CBN',
];

export const TERPENES = [
  {
    labId: 'BETA_MYRCENE',
    icon: 'mango',
    name: 'Beta Myrcene',
    short: 'MYR',
    aroma: 'Herbal',
    effects: 'Relaxing, Sedation, Heaviness, Couch Lock',
  },
  {
    labId: 'ALPHA_PINENE',
    icon: 'pine',
    name: 'Alpha Pinene',
    short: 'PIN',
    aroma: 'Pine',
    effects: 'Focus, Relief, Alert, Balanced',
  },
  {
    labId: 'ALPHA_HUMULENE',
    icon: 'hops',
    name: 'Alpha Humulene',
    short: 'HUM',
    aroma: 'Hoppy',
    effects: 'Pain relief, appetite suppression',
  },
  {
    labId: 'OCIMENE',
    icon: 'mint',
    name: 'Ocimene',
    short: 'OCIM',
    aroma: 'Mint',
    effects: 'Energizing, Uplifting',
  },
  {
    labId: 'ALPHA_BISABOLOL',
    icon: 'chamomile',
    name: 'Alpha Bisabolol',
    short: 'BIS',
    aroma: 'Floral',
    effects: 'Soothing, Anti-inflammatory',
  },
  {
    labId: 'LINALOOL',
    icon: 'lavender',
    name: 'Linalool',
    short: 'LNL',
    aroma: 'Floral',
    effects: 'Sedating feeling, relaxing, Uplifting, antidepressant',
  },
  {
    labId: 'TRANS_NEROLIDO',
    icon: 'chamomile',
    name: 'Trans-Nerolido',
    short: 'NER',
    aroma: 'Herbal',
    effects: 'Sedative, antifungal, anti-anxiety',
  },
  {
    labId: 'TRANS_NEROLIDOL',
    icon: 'chamomile',
    name: 'Trans-Nerolidol',
    short: 'NER',
    aroma: 'Herbal',
    effects: 'Sedative, antifungal, anti-anxiety',
  },
  {
    labId: 'CARYOPHYLLENE_OXIDE',
    icon: 'pepper',
    name: 'Caryophyllene Oxide',
    short: 'CARYOX',
    aroma: 'Peppery',
    effects: 'Anti-inflammatory, analgesic, calming',
  },
  {
    labId: 'TERPINOLENE',
    icon: 'apple',
    name: 'Terpinolene',
    short: 'TER',
    aroma: 'Fruity',
    effects: 'Cerebral, focused, energetic',
  },
  {
    labId: 'CAMPHENE',
    icon: 'pine',
    name: 'Camphene',
    short: 'CAM',
    aroma: 'Pine',
    effects: 'Antioxidant, anti-inflammatory',
  },
  {
    labId: 'BETA_CARYOPHYLLENE',
    icon: 'pepper',
    name: 'Beta Caryophyllene',
    short: 'CAR',
    aroma: 'Peppery',
    effects: 'Anti-inflammatory, analgesic, calming',
  },
  {
    labId: 'D_LIMONENE',
    icon: 'lemon',
    name: 'D-Limonene',
    short: 'LIM',
    aroma: 'Citrus',
    effects: 'Elevated mood, anti-anxiety',
  },
  {
    labId: 'TRANS_CARYOPHYLLENE',
    icon: 'pepper',
    name: 'Trans-Caryophyllene',
    short: 'CAR',
    aroma: 'Spicy',
    effects: 'Euphoric, Mood Enhancer, Uplifting',
  },
  {
    labId: 'BETA_PINENE',
    icon: 'pine',
    name: 'Beta Pinene',
    short: 'PIN',
    aroma: 'Pine',
    effects: 'Focus, Relief, Alert, Balanced',
  },
  {
    labId: 'DELTA_3_CARENE',
    icon: 'wood',
    name: 'Delta 3 Carene',
    short: 'D3C',
    aroma: 'Cedar',
    effects: 'Anti-inflammatory, bone health, drying agent',
  },
  {
    labId: 'ALPHA_CEDRENE',
    icon: 'wood',
    name: 'Alpha Cedrene',
    short: 'CED',
    aroma: 'Woody',
    effects: 'Antimicrobial, analgesic, anxiolytic',
  },
  {
    labId: 'ALPHA_TERPINENE',
    icon: 'lemon',
    name: 'Alpha Terpinene',
    short: 'TERPIN',
    aroma: 'Lemony',
    effects: 'Antioxidant, anti-inflammatory, antimicrobial',
  },
  {
    labId: 'CAMPHOR',
    icon: 'eucalyptus',
    name: 'Eucalyptol',
    short: 'EUC',
    aroma: 'Minty',
    effects: 'Pain relief, memory retention',
  },
  {
    labId: 'CEDROL',
    icon: 'wood',
    name: 'Cedrol',
    short: 'CEDROL',
    aroma: 'Woody',
    effects: 'Sedative, anxiolytic, relaxant',
  },
  {
    labId: 'CIS_NEROLIDOL',
    icon: 'chamomile',
    name: 'Cis-Nerolidol',
    short: 'CISNER',
    aroma: 'Floral',
    effects: 'Sedative, calming, anti-inflammatory',
  },
  {
    labId: 'ENDO_FENCHYL',
    icon: 'herb',
    name: 'Fenchol',
    short: 'FEN',
    aroma: 'Herbal',
    effects: 'Anti-bacterial, antioxidant, anti-inflammatory',
  },
  {
    labId: 'FENCHONE',
    icon: 'herb',
    name: 'Fenchone',
    short: 'FENCHO',
    aroma: 'Herbal',
    effects: 'Anti-inflammatory, antibacterial, antifungal',
  },
  {
    labId: 'GAMMA_TERPINENE',
    icon: 'lemon',
    name: 'Gamma Terpinene',
    short: 'GTERP',
    aroma: 'Citrus',
    effects: 'Antioxidant, anti-inflammatory, antimicrobial',
  },
  {
    labId: 'GERANIOL',
    icon: 'rose',
    name: 'Geraniol',
    short: 'GER',
    aroma: 'Rosy',
    effects: 'Calming, Mood boosting, Neuroprotective',
  },
  {
    labId: 'GERANYL_ACETATE',
    icon: 'floral',
    name: 'Geranyl Acetate',
    short: 'GERAC',
    aroma: 'Fruity-floral',
    effects: 'Antibacterial, mood-enhancing, stress-relieving',
  },
  {
    labId: 'GUAIOL',
    icon: 'wood',
    name: 'Guaiol',
    short: 'GUA',
    aroma: 'Rose-like',
    effects: 'Anti-inflammatory, antioxidant, antimicrobial',
  },
  {
    labId: 'HEXAHYDROTHYMOL',
    icon: 'herb',
    name: 'Hexahydrothymol',
    short: 'HEXHYDRO',
    aroma: 'Herbal',
    effects: 'Analgesic, antibacterial, antiseptic',
  },
  {
    labId: 'ISOBORNEOL',
    icon: 'mint',
    name: 'Isoborneol',
    short: 'ISOBOR',
    aroma: 'Minty',
    effects: 'Antioxidant, sedative, cardioprotective',
  },
  {
    labId: 'ISOPULEGOL',
    icon: 'herb',
    name: 'Isopulegol',
    short: 'ISOPUL',
    aroma: 'Minty',
    effects: 'Anti-inflammatory, anxiolytic, neuroprotective',
  },
  {
    labId: 'NEROL',
    icon: 'rose',
    name: 'Nerol',
    short: 'NER',
    aroma: 'Rose',
    effects: 'Antioxidant, antibacterial, anti-inflammatory',
  },
  {
    labId: 'BORNEOL',
    icon: 'rosemary',
    name: 'Borneol',
    short: 'BOR',
    aroma: 'Rosemary',
    effects: 'Calming, Stress relief',
  },
  {
    labId: 'PULEGONE',
    icon: 'chili',
    name: 'Pulegone',
    short: 'PUL',
    aroma: 'Peppermint',
    effects: 'Sedative, memory-enhancing, insect repellent',
  },
  {
    labId: 'SABINENE',
    icon: 'pepper',
    name: 'Sabinene',
    short: 'SBN',
    aroma: 'Peppery',
    effects: 'Antioxidant, anti-inflammatory',
  },
  {
    labId: 'SABINENE_HYDRATE',
    icon: 'pepper',
    name: 'Sabinene Hydrate',
    short: 'SBNHYD',
    aroma: 'Spicy',
    effects: 'Antioxidant, anti-inflammatory, antimicrobial',
  },
  {
    labId: 'VALENCENE',
    icon: 'grape',
    name: 'Valencene',
    short: 'VAL',
    aroma: 'Sweet citrus',
    effects: 'Anti-inflammatory, anti-allergic, skin protectant',
  },
  {
    labId: 'PHYTOL',
    icon: 'grass',
    name: 'Phytol',
    short: 'PHY',
    aroma: 'Grassy',
    effects: 'Antioxidant, anti-anxiety, sedative',
  },
  {
    labId: 'Phyllandrene',
    icon: 'lemon',
    name: 'Phytol',
    short: 'PHY',
    aroma: 'Peppery',
    effects: 'Anti-inflammatory, analgesic, calming',
  },
  {
    labId: 'ALPHA_CARYOPHYLLENE',
    icon: 'herb',
    name: 'Alpha Caryophyllene',
    short: 'CAR',
    aroma: '',
    effects: '',
  },
  {
    labId: 'ALPHA_CURCUMENE',
    icon: 'herb',
    name: 'Alpha Curcumene',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'ALPHA_FARNESENE',
    icon: 'herb',
    name: 'Alpha Farnesene',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'ALPHA_GURJUNENE',
    icon: 'herb',
    name: 'Alpha Gurjunene',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'ALPHA_PHELLANDRENE',
    icon: 'herb',
    name: 'Alpha Phellandrene',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'ALPHA_TERPINEOL',
    icon: 'herb',
    name: 'Alpha Terpineol',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'ALPHA_THUJONE',
    icon: 'herb',
    name: 'Alpha Thujone',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'BERGAMOTENE',
    icon: 'herb',
    name: 'Bergamotene',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'BETA_CURCUMENE',
    icon: 'herb',
    name: 'Beta Curcumene',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'BUTANE',
    icon: 'herb',
    name: 'Butane',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'CADINENE',
    icon: 'herb',
    name: 'Cadinene',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'CARVACROL',
    icon: 'herb',
    name: 'Carvacrol',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'CARVONE',
    icon: 'herb',
    name: 'Carvone',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'CIS_CITRAL',
    icon: 'herb',
    name: 'Cis Citral',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'CIS_OCIMENE',
    icon: 'herb',
    name: 'Cis Ocimene',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'CITRAL',
    icon: 'herb',
    name: 'Citral',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'CITRONELLAL',
    icon: 'herb',
    name: 'Citronellal',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'CITRONELLOL',
    icon: 'herb',
    name: 'Citronellol',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'CITRAL',
    icon: 'herb',
    name: 'Citral',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'CUMINALDEHYDE',
    icon: 'herb',
    name: 'Cuminaldehyde',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'CUPARENE',
    icon: 'herb',
    name: 'Cuparene',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'CYMENE',
    icon: 'herb',
    name: 'Cymene',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'D/L_MENTHOL',
    icon: 'herb',
    name: 'D/L Menthol',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'ETHANOL',
    icon: 'herb',
    name: 'Ethanol',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'EUGENOL',
    icon: 'herb',
    name: 'Eugenol',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'FARNESENE',
    icon: 'herb',
    name: 'Farnesene',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'FARNESOL',
    icon: 'herb',
    name: 'Farnesol',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'FENCHYL_ALCOHOL',
    icon: 'herb',
    name: 'Fenchyl Alcohol',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'GUAIAZULENE',
    icon: 'herb',
    name: 'Guaiazulene',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'ISOCANNABISPIRAN',
    icon: 'herb',
    name: 'Isocannabispiran',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'ISOLONGIFOLENE',
    icon: 'herb',
    name: 'Isolongifolene',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'LEDENE',
    icon: 'herb',
    name: 'Ledene',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'LINALYL_ACETATE',
    icon: 'herb',
    name: 'Linalyl Acetate',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'M_CYMENE',
    icon: 'herb',
    name: 'M Cymene',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'MENTHOFURAN',
    icon: 'herb',
    name: 'Menthofuran',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'MENTHOL',
    icon: 'herb',
    name: 'Menthol',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'GUAIAZULENE',
    icon: 'herb',
    name: 'Guaiazulene',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'MOISTURE',
    icon: 'herb',
    name: 'Moisture',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'NEROLIDOL',
    icon: 'herb',
    name: 'Nerolidol',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'NOOKATONE',
    icon: 'herb',
    name: 'Nookatone',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'OCTANOL',
    icon: 'herb',
    name: 'Octanol',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'ORANGE_TERPENES',
    icon: 'herb',
    name: 'Orange Terpenes',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'PARA_CYMENE',
    icon: 'herb',
    name: 'Para Cymene',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'PHELLANDRENE',
    icon: 'herb',
    name: 'Phellandrene',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'PIPERITONE',
    icon: 'herb',
    name: 'Piperitone',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'TERPINEOL',
    icon: 'herb',
    name: 'Terpineol',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'TERPINENE',
    icon: 'lemon',
    name: 'Terpinene',
    short: 'TERP',
    aroma: '',
    effects: '',
  },
  {
    labId: 'THUJOSPENE',
    icon: 'herb',
    name: 'Thujospene',
    short: '',
    aroma: '',
    effects: '',
  },
  {
    labId: 'TOTAL_TERPENES',
    icon: 'herb',
    name: 'Total Terpenes',
    short: '',
    aroma: '',
    effects: '',
  },
];

export const mockLabResults = [
  {
    result_type: 'THCA',
    amount: {
      minimum_value: '0.35',
      maximum_value: '0.35',
    },
    amount_type: 'PERCENTAGE',
  },
  {
    result_type: 'DELTA-8 THC',
    amount: {
      minimum_value: '0.22',
      maximum_value: '0.22',
    },
    amount_type: 'PERCENTAGE',
  },
  {
    result_type: 'DELTA-9 THC',
    amount: {
      minimum_value: '0.29',
      maximum_value: '0.29',
    },
    amount_type: 'PERCENTAGE',
  },
  {
    result_type: 'THCV',
    amount: {
      minimum_value: '0.12',
      maximum_value: '0.12',
    },
    amount_type: 'PERCENTAGE',
  },
  {
    result_type: 'DELTA-8 THCV',
    amount: {
      minimum_value: '0.10',
      maximum_value: '0.10',
    },
    amount_type: 'PERCENTAGE',
  },
  {
    result_type: 'CBDA',
    amount: {
      minimum_value: '0.20',
      maximum_value: '0.20',
    },
    amount_type: 'PERCENTAGE',
  },
  {
    result_type: 'CBD',
    amount: {
      minimum_value: '0.18',
      maximum_value: '0.18',
    },
    amount_type: 'PERCENTAGE',
  },
  {
    result_type: 'CBDV',
    amount: {
      minimum_value: '0.25',
      maximum_value: '0.25',
    },
    amount_type: 'PERCENTAGE',
  },
  {
    result_type: 'CBN',
    amount: {
      minimum_value: '0.38',
      maximum_value: '0.38',
    },
    amount_type: 'PERCENTAGE',
  },
  {
    result_type: 'CBC',
    amount: {
      minimum_value: '0.32',
      maximum_value: '0.32',
    },
    amount_type: 'PERCENTAGE',
  },
  {
    result_type: 'CBCa',
    amount: {
      minimum_value: '0.28',
      maximum_value: '0.28',
    },
    amount_type: 'PERCENTAGE',
  },
  {
    result_type: 'CBGA',
    amount: {
      minimum_value: '0.15',
      maximum_value: '0.15',
    },
    amount_type: 'PERCENTAGE',
  },
  {
    result_type: 'CBG',
    amount: {
      minimum_value: '0.48',
      maximum_value: '0.48',
    },
    amount_type: 'PERCENTAGE',
  },
  {
    result_type: 'BETA MYRCENE',
    amount: {
      minimum_value: '0.18',
      maximum_value: '0.18',
    },
    amount_type: 'PERCENTAGE',
  },
  {
    result_type: 'ALPHA PINENE',
    amount: {
      minimum_value: '0.21',
      maximum_value: '0.21',
    },
    amount_type: 'PERCENTAGE',
  },
  {
    result_type: 'ALPHA HUMULENE',
    amount: {
      minimum_value: '0.15',
      maximum_value: '0.15',
    },
    amount_type: 'PERCENTAGE',
  },
  {
    result_type: 'OCIMENE',
    amount: {
      minimum_value: '0.12',
      maximum_value: '0.12',
    },
    amount_type: 'PERCENTAGE',
  },
  {
    result_type: 'ALPHA BISABOLOL',
    amount: {
      minimum_value: '0.25',
      maximum_value: '0.25',
    },
    amount_type: 'PERCENTAGE',
  },
  {
    result_type: 'LINALOOL',
    amount: {
      minimum_value: '0.22',
      maximum_value: '0.22',
    },
    amount_type: 'PERCENTAGE',
  },
  {
    result_type: 'CAMPHOR',
    amount: {
      minimum_value: '0.19',
      maximum_value: '0.19',
    },
    amount_type: 'PERCENTAGE',
  },
  {
    result_type: 'BORNEOL',
    amount: {
      minimum_value: '0.27',
      maximum_value: '0.27',
    },
    amount_type: 'PERCENTAGE',
  },
  {
    result_type: 'SABINENE',
    amount: {
      minimum_value: '0.08',
      maximum_value: '0.08',
    },
    amount_type: 'PERCENTAGE',
  },
  {
    result_type: 'VALENCENE',
    amount: {
      minimum_value: '0.16',
      maximum_value: '0.16',
    },
    amount_type: 'PERCENTAGE',
  },
];
